/* 
* 업무구분: 고객 
* 화 면 명: MSPCM330M 
* 화면설명: 추가계약 추천고객 
* 작 성 일: 2024.07.09 
* 작 성 자: chanwoo8.park 
*/
<template>
  <ur-page-container :key="compKey" ref="page" class="msp" title="추가계약 추천고객" type="subpage" action-type="search" :show-title="true" :topButton="true" 
      @on-header-left-click="fn_HistoryBack" @action-search-click="fn_OpenMSPCM116P" @on-scroll-bottom="fn_LoadingData" @on-scroll="fn_OnPageScrollEvent">
    <template #frame-header-fixed>
      <!-- 전체체크 -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum" style="position: relative;">
        <div v-if="showTooltip && !isLoading" class="chat-bubble type-M1 blu2" style="min-width: 270px; bottom: -32px;">
          <div class="exp">데이터분석을 통해 업셀 가능성이<br />높은 고객이 조회됩니다.<br />우선 터치해보세요.</div>
          <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click="fn_CloseTooltip" />
        </div>
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckAll" :disabled="checkDisabled || isEmptyView"></mo-checkbox>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">{{ checkCount > 0 ? checkCount + "명 선택" : "총 " + upselRecmCustVO.length + "명" }}</span>
              <span class="fs14rem fwn mt-2" style="color: #6B7684;">* 추정소득은 마케팅동의 Y 고객만 제공</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- 검색결과 -->
      <ur-box-container v-if="showReturnStr && !isInit" alignV="start" direction="column" class="search-result-box bd-b-Ty1">
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" v-if="isMngr && pOrgData330M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{ pOrgData330M.hofOrg.label }}</span>
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData330M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{ pOrgData330M.dofOrg.label }}</span>
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData330M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{ pOrgData330M.fofOrg.label }}</span>
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData330M !== {}" @click="fn_OpenMSPCM116P">
            <span class="tit">컨설턴트</span>
            <span class="txt">{{ pOrgData330M.cnslt.label }}</span>
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[0]" @click="fn_OpenMSPCM116P">
            <span class="tit">고객</span>
            <span class="txt">{{ lv_ReturnStr[0] }}</span>
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[1]" @click="fn_OpenMSPCM116P">
            <span class="tit">성별</span>
            <span class="txt">{{ lv_ReturnStr[1] }}</span>
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[2]" @click="fn_OpenMSPCM116P">
            <span class="tit">연령대</span>
            <span class="txt">{{ lv_ReturnStr[2] }}</span>
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[3]" @click="fn_OpenMSPCM116P">
            <span class="tit">추가계약가능성</span>
            <span class="txt">{{ lv_ReturnStr[3] }}</span>
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[4]" @click="fn_OpenMSPCM116P">
            <span class="tit">추정소득</span>
            <span class="txt">{{ lv_ReturnStr[4] }}</span>
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>
    </template>

    <ur-box-container direction="column" alignV="start" class="ns-list-area">
      <!-- 조회결과 리스트 start -->
      <ur-box-container v-if="!isEmptyView" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
        <mo-list :list-data="upselRecmCustVO">
          <template #list-item="{index, item}">
            <mo-list-item :class="{ on: item.checked === true }" @click.native.stop="fn_OnClickListItem(index)">
              <!-- 체크박스 -->
              <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItemCount" :disabled="checkDisabled" :ref="`chk${index}`" />
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <!-- 고객명 -->
                  <span class="name txtSkip flex-u" @click.stop="fn_OpenCustInfoBs(item.chnlCustId, item.custNm)">{{ item.custNm }}</span>
                </div>
                <!-- 생년월일, 성별 -->
                <div class="list-item__contents__info">
                  <span>{{ item.dob }}</span
                  ><em>|</em><span>{{ item.sxdsNm.substr(0, 1) }}</span>
                </div>
                <!-- 동의내역 -->
                <div class="list-item__contents__info">
                  <span>
                    필수컨설팅
                    <b v-if="item.mndtCnstgCnsntYn === 'Y' && item.mndtCnstgCnsntRmdrPrd <= 9999" class="crTy-blue2 pl10">Y (D-{{ item.mndtCnstgCnsntRmdrPrd }})</b>
                    <b v-else-if="item.mndtCnstgCnsntYn === 'Y'" class="crTy-blue2 pl10">Y (D-9999+)</b>
                    <b v-else-if="item.mndtCnstgCnsntYn === 'N'" class="crTy-orange2 pl10">N</b>
                    <b v-else>*</b>
                  </span>
                  <em>|</em>
                  <span>
                    마케팅
                    <b v-if="item.mktCnsntYn === 'Y' && item.mktCnsntRmdrPrd <= 9999" class="crTy-blue2 pl10">Y (D-{{ item.mktCnsntRmdrPrd }})</b>
                    <b v-else-if="item.mktCnsntYn === 'Y'" class="crTy-blue2 pl10">Y (D-9999+)</b>
                    <b v-else-if="item.mktCnsntYn === 'N'" class="crTy-orange2 pl10">N</b>
                    <b v-else>*</b>
                  </span>
                </div>
                <!-- 추가계약가능성 -->
                <div class="list-item__contents__info">
                  <span class="crTy-bk7 fs14rem mr10">추가계약가능성</span>
                  <span>{{ item.upselGrdNm }}</span>
                </div>
                <!-- 추정소득 -->
                <div class="list-item__contents__info">
                  <span class="crTy-bk7 fs14rem mr10">추정소득</span>
                  <span>{{ item.asptnIncmItvNm ? item.asptnIncmItvNm : '-' }}</span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>
      <!-- 조회결과 리스트 end -->

      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->

      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
      <div v-if="upselRecmCustVO.length > 0" class="ns-height40"></div>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/************************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ************************************************************************************************/
import Msg from "@/systems/webkit/msg/msg";
import MSPCM116P from "@/ui/cm/MSPCM116P"; // 추가계약 추천고객 목록 검색
import MSPCM144P from "@/ui/cm/MSPCM144P"; // 세미나 신청
import MSPCM146P from "@/ui/cm/MSPCM146P"; // 대출추천
import MSPCM147P from "@/ui/cm/MSPCM147P"; // 라벨출력
import MSPBottomAlert from "@/ui/_cmm/components/MSPBottomAlert";
import Screen from "~systems/mixin/screen";

export default {
  /************************************************************************************************
   * Vue 파일 속성 정의 영역
   ************************************************************************************************/
  // 현재 화면명
  name: "MSPCM330M",
  // 현재 화면 ID
  screenId: "MSPCM330M",
  // 컴포넌트 선언
  components: {
    MSPCM116P,
    MSPCM144P,
    MSPCM146P,
    MSPCM147P,
  },
  mixins: [Screen],
  /************************************************************************************************
   * 화면 전역 변수 선언 영역
   ************************************************************************************************/
  data() {
    return {
      popup116: {}, // MSPCM116P 추가계약 추천고객 목록 검색
      lv_ReturnStr: [], // 상세조회조건 텍스트 배열

      // index-scroll
      indexStyle: {
        top: "150px",
        height: "calc(100vh - 260px)",
        position: "fixed",
      },
      indexStyle1: {
        top: "250px",
        height: "calc(100vh - 330px)",
        position: "fixed",
      },
      scrollElementId: "",

      isInit: false, // 검색 초기화 여부
      isEmptyView: false, // 데이터 없음 화면
      isSearched: false, // 조회이력 저장 변수
      isMngr: false, // 지점장 여부
      isOneOrMoreCheck: false, // 전체선택
      checkDisabled: false, // 체크박스 비활성화 여부
      isLoading: false,
      showTooltip: true, // 툴팁 여부

      compKey: 0,
      userInfo: {}, // 사용자정보 obj
      pOrgData330M: {}, // 최근 조직 조회 데이터
      lv_cnsltNo: "", // 컨설턴트 번호
      searchKeyword: "", // 고객명
      sxdsCd: "0", // 성별
      asptnIncmItvCd: "00", // 추정소득
      upselGrdNm: "", // 추가계약가능성
      ageItvCd: "0", // 연령대구분
      lv_inputParamS1: {}, // 추가계약 추천고객목록 조회 파라미터 Obj
      upselRecmCustVO: [], // 고객 조회용 Service IF VO
      stndKeyList: [
        { stndKeyId: "nextKey", stndKeyVal: "" },
        { stndKeyId: "rowCnt", stndKeyVal: 20 },
      ], // 더보기 변수
      stndKeyVal: "", // 더보기 변수
      pageRowCnt: '20', // 페이지 count

      crntScrollPosition: 0, // KeppAlive 스크롤 위치 변수
      pageScrollId: "scroll_MSPCM330M", // 화면 Scroll 영역 ID
    };
  },
  /************************************************************************************************
   * Life Cycle 함수 정의 영역
   ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    selectItems() {
      return this.upselRecmCustVO.filter((item) => item.checked);
    },
    checkCount() {
      return this.upselRecmCustVO.filter((item) => item.checked).length;
    },
    showReturnStr() {
      if (this.isMngr && !_.isEmpty(this.pOrgData330M)) {
        return true;
      }
      if (this.lv_ReturnStr.length > 0) {
        for (let i in this.lv_ReturnStr) {
          if (!this.$bizUtil.isEmpty(this.lv_ReturnStr[i])) return true;
        }
      }
      return false;
    },
  },
  /** watch 정의 영역 */
  watch: {
    checkCount() {
      if (this.checkCount === this.upselRecmCustVO.length && this.upselRecmCustVO.length !== 0) {
        this.isOneOrMoreCheck = true;
      } else {
        this.isOneOrMoreCheck = false;
      }
    },
  },
  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created() {
    this.fn_Init();
  },
  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted() {
    // index Scroll 설정
    this.scrollElementId = this.$refs.page.getScrollElementId();
    document.getElementById(this.scrollElementId).addEventListener("scroll", (e) => {
      const scrollTop = e.target.scrollTop;
      this.indexStyle = {
        top: Math.max(150 - scrollTop, 116) + "px",
        height: `calc(100vh - ${Math.max(260 - scrollTop, 226)}px)`,
        position: "fixed",
      };
    });
    
    // 툴팁
    this.showTooltip = !localStorage.getItem('READ_MSPCM330M_TOOLTIP');

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog("MSPCM330M");
  },
  activated() {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack);
    // 진입 시 재조회
    this.compKey += 1;

    if (this.pageScrollId) {
      const lv_Vm = this;

      // 스크롤 이동 처리
      setTimeout(() => {
        if (!document.getElementById(lv_Vm.pageScrollId)) return;

        document.getElementById(lv_Vm.pageScrollId).scrollTop = lv_Vm.crntScrollPosition;
      }, 500);
    }
  },
  deactivated() {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack);

    // cmStore 초기화
    window.vue.getStore("cmStore").dispatch("ADD", {});
  },
  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy() {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack);

    // cmStore 초기화
    window.vue.getStore("cmStore").dispatch("ADD", {});
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
   ************************************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
      if (this.checkDisabled) return;
      this.$refs[`chk${index}`].onClick();
    },
    /*********************************************************
     * @function fn_HistoryBack
     * @notice   뒤로가기 버튼
     *********************************************************/
    fn_HistoryBack() {
      if (this.checkCount > 0) this.fn_confirm();
      else {
        if (this.isSearched) {
          // 조회변수 초기화
          this.fn_InitValue();
          // 재조회
          this.fn_Btn_S1Click();
        } else {
          // 스크롤 초기화
          this.crntScrollPosition = 0;

          // 조회를 하지 않았으면 뒤로가기
          let custInfo = window.vue.getStore("cmStore").getters.getState.custInfo;
          if (custInfo.isClear) {
            this.$router.push({ name: "MSPBC002M" });
          } else {
            if (this.$route.query?._isMenuItem) {
              this.$router.push({ name: "MSPBC002M" });
              return;
            }
            this.$router.go(-1);
          }
        }
      }
    },
    /*********************************************************
     * @function fn_Init
     * @notice   화면 진입 시 실행하는 초기 함수
     *********************************************************/
    fn_Init() {
      // 사용자 정보
      this.userInfo = this.getStore("userInfo").getters.getUserInfo;
      this.lv_cnsltNo = this.userInfo.userId; // 컨설턴트 번호

      // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
      if (this.$cmUtil.isSrchOrgCombo() === "Y") {
        this.isMngr = true;
      }

      // 조회변수 초기화
      this.fn_InitValue();

      // 조회
      this.fn_Btn_S1Click();
    },
    /*********************************************************
     * @function fn_Btn_S1Click
     * @notice   고객명 조회
     *********************************************************/
    fn_Btn_S1Click() {
      this.stndKeyList = [
        { stndKeyId: "nextKey", stndKeyVal: "" },
        { stndKeyId: "rowCnt", stndKeyVal: 20 },
      ];
      this.stndKeyVal = ""; // 더보기 최초조회
      this.upselRecmCustVO = [];
      this.isEmptyView = false; // 데이터가 없습니다 visible 여부

      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (this.pOrgData330M.cnslt !== undefined && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData330M.cnslt.key) {
          this.checkDisabled = true;
        } else {
          this.checkDisabled = false;
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false;
      }

      // post 호출 전 Progress 타입 설정. 미설정시 FULL(전체화면)이 기본값
      window.vue.getStore("progress").dispatch("SKEL_LIST_CHK");

      // 추가계약 추천고객목록 조회
      this.fn_SelListUpselRecmCust();
    },
    /*********************************************************
     * @function fn_SetParamS1
     * @notice   파라미터 세팅(추가계약 추천고객목록 조회)
     *********************************************************/
    fn_SetParamS1() {
      this.lv_inputParamS1 = {}; // Input 객체 초기화

      // 지점장 조회시 구분
      if (this.pOrgData330M.cnslt !== undefined && this.isMngr === true) {
        this.lv_inputParamS1.cnsltNo = this.pOrgData330M.cnslt.key; // 선택한 컨설턴트 번호
        // this.lv_inputParamS1.fofOrgNo = this.pOrgData330M.fofOrg.key; // 선택한 지점조직번호
      } else {
        this.lv_inputParamS1.cnsltNo = this.userInfo.userId; // 컨설턴트 번호
        // this.lv_inputParamS1.fofOrgNo = this.userInfo.onpstFofOrgNo; // 지점조직번호
      }

      this.lv_inputParamS1.custNm = this.searchKeyword; // 고객명
      this.lv_inputParamS1.sxdsCd = this.sxdsCd; // 0: 전체, 1: 여성, 2: 남성
      this.lv_inputParamS1.asptnIncmItvCd = this.asptnIncmItvCd; // 00: 전체(값없음 포함), 01: 2천만원미만(값없음 포함), 02: 2천만원이상, 03: 4천만원이상, 04: 6천만원이상, 05: 8천만원이상, 06: 1억이상
      this.lv_inputParamS1.upselGrdNm = this.upselGrdNm; // '': 전체, '상': 상, '중': 중, '하': 하
      this.lv_inputParamS1.ageItvCd = this.ageItvCd; // 1: 미성년자, 2: 20대, 3: 30대, 4: 40대, 5: 50대, 6: 60대, 7: 70대이상
    },
    /*********************************************************
     * @function fn_SelListUpselRecmCust
     * @notice   추가계약 추천고객목록 조회(최초 로딩 시 실행)
     *********************************************************/
    fn_SelListUpselRecmCust() {
      // 마지막 페이지 return
      if (this.stndKeyVal === "end") return;

      const lv_Vm = this;
      const trnstId = "txTSSCM15S3";
      const auth = "S";
      let pParams = {};

      // 파라미터 세팅
      this.fn_SetParamS1();

      pParams = this.lv_inputParamS1;

      this.isLoading = true;
      this.lv_TotalCnt = 0;

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function(response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== "") {
            let responseList = response.body.upselRecmCustVO;

            for (let i = 0; i < responseList.length; i++) {
              responseList[i].checked = false;
            }

            if (lv_Vm.stndKeyVal !== "") {
              // 더보기
              for (let i = 0; i < responseList.length; i++) {
                lv_Vm.upselRecmCustVO.push(responseList[i]);
              }
            } else {
              // 1페이지
              lv_Vm.upselRecmCustVO = responseList;
            }

            if (responseList.length === 0) {
              lv_Vm.isEmptyView = true; // 데이터가 없습니다 visible true
            } else {
              lv_Vm.isEmptyView = false; // 데이터가 없습니다 visible false
            }
          } else {
            lv_Vm.isEmptyView = true; // 데이터가 없습니다 visible true
            lv_Vm.getStore("confirm").dispatch("ADD", response.msgComm.msgDesc);
          }

          // 더보기 키값
          lv_Vm.stndKeyList = response.trnstComm.stndKeyList;

          // 다음 데이터 존재시 more, 없으면 end
          if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === "") {
            lv_Vm.stndKeyVal = "end";
          } else {
            lv_Vm.stndKeyVal = "more";
          }

          let resultList = lv_Vm.upselRecmCustVO;

          lv_Vm.lv_TotalCnt = resultList === null ? 0 : resultList.length;

          lv_Vm.isLoading = false;
        })
        .catch(function(error) {
          lv_Vm.isEmptyView = true; // 데이터가 없습니다 visible true
          lv_Vm.isLoading = false;
          window.vue.error(error);
        });
    },
    /*********************************************************
     * @function fn_LoadingData
     * @notice   고객목록 infinite 로딩
     *********************************************************/
    fn_LoadingData() {
      // 더보기 시 전체체크 되어있으면 전체체크 해제
      if (this.isOneOrMoreCheck && this.stndKeyVal !== "end") {
        this.isOneOrMoreCheck = false;
      }

      // 추가계약 추천고객목록 조회
      // post 호출 전 Progress 타입 설정. 미설정시 FULL(전체화면)이 기본값
      window.vue.getStore("progress").dispatch("PART");

      if (!this.isLoading) {
        this.fn_SelListUpselRecmCust();
      }
    },
    /*********************************************************
     * @function fn_CheckAll
     * @notice   조회목록 전체선택
     *********************************************************/
    fn_CheckAll() {
      this.$nextTick(() => {
        if (this.upselRecmCustVO.length > 0) {
          // 리스트 존재시에만 작동
          for (let n in this.upselRecmCustVO) {
            if (this.isOneOrMoreCheck) {
              this.upselRecmCustVO[n].checked = true;
            } else {
              this.upselRecmCustVO[n].checked = false;
            }
          }

          // 고객 체크 시 BottomSheet
          this.fn_CheckSelectedItemCount();
        }
      });
    },
    /*********************************************************
     * @function fn_CheckSelectedItemCount
     * @notice   체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다. 30명 이상 문자전송 불가
     *********************************************************/
    fn_CheckSelectedItemCount() {
      // 고객 체크 시 BottomSheet
      // 배열로 넘기기 (Array.isArray(selectItems) === true 로 확인)
      this.$cmUtil.setSelectedCustList(this, this.selectItems);
    },
    /*********************************************************
     * @function fn_InitValue
     * @notice   주요 변수 초기화
     *********************************************************/
    fn_InitValue() {
      // 체크된 고객 초기화
      let selectItems_init = [];
      this.$cmUtil.setSelectedCustList(this, selectItems_init);

      this.pOrgData330M = {}; // 조직 조회 데이터
      this.searchKeyword = '' // 고객명
      this.sxdsCd = "0"; // 성별
      this.asptnIncmItvCd = "00"; // 추정소득
      this.upselGrdNm = ""; // 추가계약가능성
      this.ageItvCd = "0"; // 연령구분

      this.isSearched = false; // 검색이력 초기화
      this.isInit = true; // 검색초기화 여부
    },
    /*********************************************************
     * @function fn_OpenMSPCM116P
     * @notice   고객명 검색 팝업 호출
     *********************************************************/
    fn_OpenMSPCM116P() {
      let lv_Vm = this;

      this.popup116 = this.$bottomModal.open(MSPCM116P, {
        properties: {
          pPage: "MSPCM330M",
          pOrgData: this.pOrgData330M, // 최근 조직 조회 데이터
          pSearchKeyword: this.searchKeyword, // 고객명
          pSxdsCd: this.sxdsCd, // 성별
          pAsptnIncmItvCd: this.asptnIncmItvCd, // 추정소득
          pUpselGrdNm: this.upselGrdNm, // 추가계약가능성
          pAgeItvCd: this.ageItvCd, // 연령
          pIsMngr: this.isMngr, // 지점장 여부
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            lv_Vm.$bottomModal.close(lv_Vm.popup116);

            lv_Vm.searchKeyword = pData.rtnData.searchKeyword
            lv_Vm.sxdsCd = pData.rtnData.upselSxdsCd;
            lv_Vm.ageItvCd = pData.rtnData.upselAgeItvCd;
            lv_Vm.asptnIncmItvCd = pData.rtnData.upselAsptnIncmItvCd;
            lv_Vm.upselGrdNm = pData.rtnData.upselGrdNm;

            // 마지막 조직 조회 데이터 셋팅
            lv_Vm.pOrgData330M = pData.rtnData.pOrgData;

            // 조회이력 저장
            lv_Vm.isSearched = true;
            // 검색 초기화 여부
            lv_Vm.isInit = pData.isInit;
            if (lv_Vm.isInit) lv_Vm.isSearched = false;
            // 조회조건 텍스트 세팅
            lv_Vm.lv_ReturnStr = pData.srchOpt;

            // 스크롤 초기화
            lv_Vm.crntScrollPosition = 0;

            // 고객명 조회
            lv_Vm.fn_Btn_S1Click();
          },
        },
      });
    },
    /*********************************************************
     * @function fn_OpenCustInfoBs
     * @notice   네임UI 바텀시트 (고객명터치시)
     *********************************************************/
    fn_OpenCustInfoBs(chnlCustId, custNm) {
      let lv_Vm = this;
      let cnsltNo = "";
      let nmUiObject = {};

      if (this.pOrgData330M.cnslt === undefined || this.pOrgData330M.cnslt === null || this.pOrgData330M.cnslt === "") {
        cnsltNo = this.userInfo.userId;
      } else {
        cnsltNo = this.pOrgData330M.cnslt.key;
      }

      nmUiObject = {
        chnlCustId: chnlCustId, //고객채널 ID
        parentId: "MSPCM330M", //화면ID+
        cnsltNo: cnsltNo,
        custNm: custNm,
        contNo: "",
      };

      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, (modalId) => {
        //Close 콜백 처리
        return;
      });
    },
    /*********************************************************
     * @function fn_CloseTooltip
     * @notice   툴팁 닫기
     *********************************************************/
    fn_CloseTooltip() {
      this.showTooltip = false;
      localStorage.setItem('READ_MSPCM330M_TOOLTIP', true);
    },
    /*********************************************************
     * @function fn_confirm
     * @notice   confirm popup
     *********************************************************/
    fn_confirm() {
      let lv_Vm = this;
      let backMsg = "선택을 그만두고<br>이전 화면으로 이동하시겠습니까?";
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          type: "B",
          content: backMsg,
          title_pos_btn: "예",
          title_neg_btn: "아니오",
        },
        listeners: {
          onPopupConfirm: () => {
            lv_Vm.$bottomModal.close(lv_AlertPop);

            /**
             * (2023.07.11 추가계약 추천고객 조회, 체크박스 해제 / 김미정 프로)
             * "예" 버튼 클릭시, 추가계약 추천고객 조회 초기화
             * 메인 홈으로 이동시, 체크박스 해제
             */
            lv_Vm.crntScrollPosition = 0; // 스크롤 초기화

            // 조회변수 초기화
            lv_Vm.fn_InitValue();
            // 재조회
            lv_Vm.fn_Btn_S1Click();

            if (!lv_Vm.isSearched) {
              // 전체선택 해제 (2023.07.11 해당 팝업으로 나갔을때만, 체크박스 해제 / 김미정 프로)
              lv_Vm.isOneOrMoreCheck = false;
              lv_Vm.fn_CheckAll();

              // 조회를 하지 않았으면 뒤로가기
              let custInfo = window.vue.getStore("cmStore").getters.getState.custInfo;
              if (custInfo.isClear) {
                this.$router.push({ name: "MSPBC002M" });
              } else {
                if (this.$route.query?._isMenuItem) {
                  this.$router.push({ name: "MSPBC002M" });
                  return;
                }
                this.$router.go(-1);
              }
            }
          },
          onPopupClose: () => {
            lv_Vm.$bottomModal.close(lv_AlertPop);
          },
        },
      });
    },
    /*********************************************************
     * @function fn_OnPageScrollEvent
     * @notice   고객목록 스크롤 이벤트 메서드
     *********************************************************/
    fn_OnPageScrollEvent(e) {
      let target = e.target;
      if (!target) return;

      let scrollPosition = target.scrollTop;
      this.crntScrollPosition = scrollPosition;
    },
  },
};
</script>